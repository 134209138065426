import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

interface Props {
  label: string;
  name: string;
  options: {value: string|number, label: string}[],
}

const SelectInput: React.FC<Props> = ({ name, options, label }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <select 
        name={name} 
        ref={selectRef}
        defaultValue={defaultValue}
      >
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
      <span className="error">{ error && "*"+error}</span>
    </>
  );
};

export default SelectInput;