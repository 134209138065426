import React from 'react';
// Components
import { Link } from 'react-router-dom';
import Logo from '../Logo';
// Context
import { useAuth } from '../../contexts/auth';
// Styles
import './styles.css';

interface Props {
  active?: 'templates' | 'clients' | 'dashboard'
}

const Header: React.FC<Props> = ({ active='clients' }) => {
  const { signOut } = useAuth();

  return (
    <nav>
      <div className="nav-title">
        <Logo />
        <span className="nav-title--text">TrainerBox</span>
      </div>
    
      <div className="nav-links">
        <Link 
          to="/dashboard/"
          className={active === 'dashboard' ? 'active' : ''}
        >
          Painel
        </Link>
        <Link 
          to="/"
          className={active === 'clients' ? 'active' : ''}
        >
          Clientes
        </Link>
        <Link 
          to="/templates/" 
          className={active === 'templates' ? 'active' : ''}
        >
          Modelos
        </Link>
        <button onClick={signOut} className="nav-links--signout">Sair</button>
      </div>
    </nav>
  )
}

export default Header;