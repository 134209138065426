import React from 'react';
import { FiTrash, FiPlus, FiPrinter, FiX, FiEdit3, FiDollarSign, FiDownload } from 'react-icons/fi'
import { Link } from 'react-router-dom';

import './styles.css';

interface Props {
  onClick?: () => void;
  submit?: boolean;
}

interface LinkButtonProp {
  to: string;
}

export const Button: React.FC<Props> = ({children, onClick, submit}) => (
  <button 
    className="button" 
    onClick={onClick}
    type={submit ? 'submit' : 'button'}
  >
    {children}
  </button>
)

export const DownloadButton: React.FC<Props> = ({onClick}) => (
  <Button onClick={onClick}>
    <FiDownload/>
  </Button>
)

export const DeleteButton: React.FC<Props> = ({onClick}) => (
  <button className="button--delete" onClick={onClick}>
    <FiTrash/>
  </button>
)

export const CancelButton: React.FC<Props> = ({onClick}) => (
  <button className="button--delete" onClick={onClick}>
    <FiX/>
  </button>
)

export const PrintButton: React.FC = () => (
  <button className="button" onClick={() =>  window.print()}>
    <FiPrinter/>
  </button>
)

export const EditButton: React.FC<LinkButtonProp> = ({to}) => (
  <Link to={to} className="button">
    <FiEdit3/>
  </Link>
)

export const PayButton: React.FC<Props> = ({onClick}) => (
  <button className="button button--pay" onClick={onClick}>
    <FiDollarSign/>
  </button>
)

export const AddLinkButton: React.FC<LinkButtonProp> = ({to}) => (
  <Link to={to} className="button">
    <FiPlus/>
  </Link>
)