import React, { useEffect, useRef} from 'react';
import { useField } from '@unform/core';

interface Props {
  name: string;
  rows: number;
  label: string;
}

const TextArea: React.FC<Props> = ({ name, rows, label, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <textarea 
        ref={inputRef}
        defaultValue={defaultValue}
        rows={rows}
        {...rest} />
      <span className="error">{ error && "*"+error}</span>
    </>
  )

}

export default TextArea;