import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// Components
import { DownloadButton, DeleteButton } from '../../components/Buttons';
import PageContainer from '../../components/PageContainer';
import { ReceiptCard, ReceiptRef } from '../../components/ReceiptCard';
import { CSSTransition } from 'react-transition-group';
// Types
import { Receipt } from '../../@types/Payment';
import Client from '../../@types/Client';
// Styles
import './styles.css';
// Services
import api from '../../services/api';

interface Params {
  clientId: string;
  receiptId: string;
}

const ReceiptDetail = () => {
  const history = useHistory();
  const {clientId, receiptId} = useParams<Params>();

  const receiptRef = useRef<ReceiptRef>(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [receiptData, setReceiptData] = useState<Receipt>();
  const [clientData, setClientData] = useState<Client>();

  useEffect(() => {
    async function getReceiptData() {
      try {
        const response = await api.get(`/receipts/${receiptId}/`);
        setReceiptData(response.data);
        setClientData(response.data.client);
        setDataLoaded(true);
      } catch (error) {
        history.push('/404/');
      }
    }
    getReceiptData();
  }, [clientId, receiptId, history]);

  async function handleDelete() {
    const deleteReceipt = window.confirm("Tem certeza que deseja deletar esse recibo?");
    if(deleteReceipt) {
      await api.delete(`/receipts/${receiptId}/`);
      toast.info('Recibo deletado com sucesso!');
      history.push(`/clients/${clientId}/`);
    }
  }

  function exportReceipt() {
    receiptRef.current?.exportReceipt();
  }

  return (
    <>
      <PageContainer dataLoaded={dataLoaded}>
        <div className="main-bar">
          <h1>#{receiptId} - {clientData?.name}</h1>
          <div className="main-bar--buttons">
            <DownloadButton onClick={exportReceipt} />
            <DeleteButton onClick={handleDelete}/>
          </div>
        </div>
      </PageContainer>

      <CSSTransition
          in={(dataLoaded && receiptData) ? true : false}
          timeout={350}
          apear
        >
        <div className={((dataLoaded && receiptData) ? true : false)?'fadeIn':'fadeOut'}>
          {receiptData &&
            <ReceiptCard ref={receiptRef} data={receiptData}/>
          }
        </div>
      </CSSTransition>
    </>
  );
}

export default ReceiptDetail;
