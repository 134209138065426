import React, { useEffect, useRef} from 'react';
import { useField } from '@unform/core';

import './styles.css';

interface Props  {
  name: string;
  label: string;
}

const CheckboxInput: React.FC<Props> = ({ name, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        return ref.checked;
      },
      setValue: (ref: HTMLInputElement, value: boolean) => {
        ref.checked = value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <label className="checkbox">{label}
      <input 
        ref={inputRef}
        defaultValue={defaultValue}
        type="checkbox"
        {...rest}
      />
      <span className="checkmark"></span>
    </label>
  )

}

export default CheckboxInput;