import React, { useState } from 'react';
// Components
import { Form } from '@unform/web';
import { Button } from '../../components/Buttons';
import Input from '../../components/Input';
import Logo from '../../components/Logo';
// Context
import { useAuth } from '../../contexts/auth';
// Services
import api from '../../services/api';
// Styles
import './styles.css';

interface Login {
  email: string,
  password: string,
}


const Login = () => {
  const { signIn } = useAuth();

  const [loginData] = useState<Login>({
    email: "",
    password: "",
  });
  const [error, setError] = useState('');


  async function handleSignIn(data: Login) {
    if(!data.email || !data.password) {
      setError('Preencha o e-mail e senha para continuar!');
    } else {
      try {
        const response  = await api.post('/token/', data);
        const token = response.data.access;
        const userResponse = await api.get('/user_info/', {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        })
        signIn(token, userResponse.data);
      } catch (error) {
        console.log(error);
        setError('Houve um problema com o login. Verifique suas credencias.');
      }
    }
  }

  return (
    <>
      <main className="login-card">
        <div className="login-card--logo">
          <Logo/>
          <span>TrainerBox</span>
        </div>

        <Form onSubmit={handleSignIn} initialData={loginData}>
          {error !== '' && 
            <div className="login-card-error">
              {error}
            </div>
          }

          <div className="login-card--field">
            <Input type="email" name="email" label="Email:" required/>
          </div>

          <div className="login-card--field">
            <Input type="password" name="password" label="Senha:" required/>
          </div>

          <Button submit>Login</Button>
        </Form>
      </main>

    </>
  );
}

export default Login;
