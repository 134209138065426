import React from 'react';
// Components
import Modal from 'react-modal';

import './styles.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AppModal: React.FC<Props> = ({children, isOpen, onClose}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    style={{
      content : {
        width                 : '40%',
        maxHeight             : '50%',
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      }
    }}
  >
    {children}
  </Modal>
)
