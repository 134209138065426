import React, { useRef, useImperativeHandle } from 'react';
// @types
import { Receipt } from '../../@types/Payment';
import { useAuth } from '../../contexts/auth';
import { formatDate, formatAmount } from '../../utils/format';
// Installed
import { exportComponentAsJPEG } from 'react-component-export-image';
// Styles
import './styles.css'

interface Props {
  data: Receipt;
}

export interface ReceiptRef {
  exportReceipt: () => void;
}

export const ReceiptCard = React.forwardRef<ReceiptRef, Props>(({ data }, ref) => {
  const { user } = useAuth();
  const receiptRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({exportReceipt}));

  function exportReceipt() {
    exportComponentAsJPEG(receiptRef, {
      fileName: "recibo"+data.id,
      html2CanvasOptions: {
        backgroundColor: "white",
      }
    });
  }

  return (
      <div className="receipt-container">
        <div ref={receiptRef} className="receipt">
          <div style={{backgroundColor: "white", padding: 10}}>
            <div className="receipt-header">
              <div className="receipt-company">
                <div className="receipt-company--logo" >
                  <img src={user?.company.logo} alt={"Logo "+user?.company.name}/>
                </div>
                <div className="receipt-company--info" >
                  <span>{user?.company.name}</span>
                  <span>{user?.company.cnpj}</span>
                  <span>{user?.company.address}</span>
                  <span>{user?.company.city}</span>
                </div>
              </div>
              <h2 className="receipt-header--text">Recibo #{data.id}</h2>
            </div>

            <div className="receipt-row">
              <div className="receipt-field">
                <label>Data:</label>
                <span>{ formatDate(data.date) }</span>
              </div>
              <div className="receipt-field">
                <label>Valor:</label>
                <span>{ formatAmount(data.amount) }</span>
              </div>
            </div>

            <div className="receipt-row">
              <div className="receipt-field">
                <label>Cliente:</label>
                <span>{ data.client.name }</span>
              </div>
              <div className="receipt-field">
                <label>Referente à:</label>
                <span>{ data.description }</span>
              </div>
            </div>

            <div className="receipt-signature">
                <span>____________________________________</span>
                <span>{data.company.owner}</span>
            </div>
          </div>

        </div>
      </div>
  )
})