import React, { useRef, useEffect, useState } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';

interface Props extends InputProps {
  name: string;
  label: string;
}

const InputMask: React.FC<Props> = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  // Part of the hack
  const [maskInputValue, setMaskInputValue] = useState(defaultValue.slice());
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  // hacked to make it work
  function onTextChange(event: any) {
    setChanged(true);
    setMaskInputValue(event.target.value);
  }

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <ReactInputMask 
        ref={inputRef}
        value={changed? maskInputValue : defaultValue}
        onChange={onTextChange}
        {...rest} 
      />
      <span className="error">{ error && "*"+error}</span>
    </>
  );
};
export default InputMask;