import React, { useState, useEffect } from 'react';
import { FiFile, FiX } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// Components
import PageContainer from '../../components/PageContainer';
import PrintWorkout from '../../components/PrintWorkout';
import WorkoutEdit from '../../components/WorkoutEdit';
import { Button, PrintButton } from '../../components/Buttons';
import Modal from 'react-modal';
// Types
import Workout from '../../@types/Workout';
// Styles
import './styles.css';
// Services
import api from '../../services/api';
import useDebounce from '../../hooks/useDebounce';

interface Params {
  clientId: string;
  workoutId: string;
}

// Bindo modoal to app?
Modal.setAppElement('#root')

const WorkoutForm = () => {
  const history = useHistory();
  const {clientId , workoutId} = useParams<Params>();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [templateList, setTemplateList] = useState([{id: 0, name: ""}]);
  const [searchTextTemplate, setSearchTextTemplate] = useState('');
  const debouncedSearchTextTemplate = useDebounce(searchTextTemplate, 500);

  const [workoutLabel, setWorkoutLabel] = useState('Novo Treino');
  const [workouts, setWorkouts] = useState<Workout[]>([]);

  const [dateStart, setDateStart] = useState(new Date().toISOString().split("T")[0]);
  const [dateEnd, setDateEnd] = useState(new Date(Date.now()+60*24*60*60*1000).toISOString().split("T")[0]);

  useEffect(() => {
    // Fetch workout data
    async function getWorkoutData() {
      const response = await api.get(`/workouts/${workoutId}/`);
      if(response.data.client === parseInt(clientId)) {
        setWorkouts(response.data.data);
        setDateStart(response.data.start);
        setDateEnd(response.data.end);
        setDataLoaded(true);
      } else {
        toast.error("Esse treino não pertence a esse cliente");
        history.push('/');
      }
    }

    // Make Title and fixed client ID
    async function getClientData() {
      try {
        const response = await api.get(`/clients/${clientId}/`);
        const activeWorkoutIndex = response.data.workouts.findIndex(
          (w: any, index: number) => {
            if (w.id === parseInt(workoutId)) {
              return true;
            }
            return false;
          })
        if (activeWorkoutIndex !== -1) {
          const activeWorkout = response.data.workouts.length - activeWorkoutIndex
          setWorkoutLabel(`Treino #${activeWorkout} - ${response.data.name}`);
        } else {
          setWorkoutLabel(`Novo Treino - ${response.data.name}`);
        }
        setDataLoaded(true);
      } catch (error) {
        history.push('/404/');
      }
    }
    if(workoutId !== "new") {
      getWorkoutData();
    }
    getClientData();
  }, [clientId, workoutId, history]);
  
  async function handleFormSubmit() {
    const workoutObject = {
      client: clientId,
      start: dateStart,
      end: dateEnd,
      data: workouts,
    }

    let message = ""
    if(workoutId === "new") {
      // Create
      await api.post('/workouts/', workoutObject);
      message = 'Treino criado com sucesso!';
    } else {
      // Update
      await api.put(`/workouts/${workoutId}/`, workoutObject);
      message = 'Treino atualizado com sucesso!';
    }
    toast.info(message);
    history.push(`/clients/${clientId}/`);
  }

  function closeLoadTemplate() {
    setModalIsOpen(false);
  }

  async function getTemplates() {
    const response = await api.get('/templates/?nopaginate=0');
    setTemplateList(response.data);
  }

  async function openLoadTemplate() {
    await getTemplates();
    setModalIsOpen(true);
  }

  async function loadTemplate(id: number) {
    const response = await api.get(`/templates/${id}/`);
    setWorkouts(response.data.data);
    closeLoadTemplate();
  }

  useEffect(() => {
    async function searchTemplates(searchTerm: string) {
      const response = await api.get(`/templates/?search=${searchTerm}`);
      setTemplateList(response.data);
    }

    if(debouncedSearchTextTemplate) {
      searchTemplates(debouncedSearchTextTemplate);
    } else {
      getTemplates();
    }
  }, [debouncedSearchTextTemplate]);

  return (
    <>
    <PageContainer dataLoaded={dataLoaded}>
        <div className="main-bar">
          <h1>{workoutLabel}</h1>
          <div className="main-bar--buttons">
            <PrintButton />
            <Button onClick={openLoadTemplate}><FiFile/></Button>
            <Button onClick={handleFormSubmit}>Finalizar</Button>
          </div>
        </div>

        <div>
          <div className="workout-detail--container">
            <div className="workout-detail--field">
              <label htmlFor="name">Inicio:</label>
              <input 
                type="date" 
                name="date_start"
                value={dateStart}
                onChange={(e) => setDateStart(e.target.value)}
                />
            </div>

            <div className="workout-detail--field">
              <label htmlFor="date">Fim:</label>
              <input 
                type="date" 
                name="date_end"
                value={dateEnd}
                onChange={(e) => setDateEnd(e.target.value)}
              />
            </div>
          </div>

          <WorkoutEdit 
            workouts={workouts}
            onChange={(workouts) => setWorkouts(workouts)}
          />

        </div>

       <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeLoadTemplate}
          style={{
            content : {
              width                 : '20%',
              maxHeight             : '40%',
              top                   : '50%',
              left                  : '50%',
              right                 : 'auto',
              bottom                : 'auto',
              marginRight           : '-50%',
              transform             : 'translate(-50%, -50%)'
            }
          }}
        >
          <div className="main-bar">
            <h1 className="nav-title">Carregar Modelo</h1>
            <Button onClick={closeLoadTemplate}><FiX/></Button>
          </div>
          <div className="client-content">
            <input 
              type="text"
              placeholder="Busca"
              onChange={({target}) => {setSearchTextTemplate(target.value)}}
              value={searchTextTemplate}
            />
            <div className="list">
              {
                templateList.map((template) => {
                  return (
                    <div 
                      onClick={() => loadTemplate(template.id)}
                      key={template.id}
                      className="list--item"
                    >
                      <span>{template.name}</span>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Modal>
    </PageContainer>

    <PrintWorkout 
      workouts={workouts}
      start={dateStart}
      end={dateEnd}
      label={workoutLabel} />
    </>
  );
}

export default WorkoutForm;
