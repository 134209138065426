import React from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
// Types
import { PaymentData } from '../../@types/Payment';
// Components
import Input from '../Input';
import MoneyInput from '../MoneyInput';
import CheckboxInput from '../CheckboxInput';
import SelectInput from '../SelectInput';
// Configs
import { YupLocaleConfig } from '../../utils/configs';
// Styles
import './styles.css';

type Props = {
  data: PaymentData;
  onSubmit: SubmitHandler<PaymentData>;
}

const PaymentEditForm = React.forwardRef<FormHandles, Props>((props, ref) => {

  const handleSubmit: SubmitHandler<PaymentData> = async (data, helpers) => {
    try {
      Yup.setLocale(YupLocaleConfig)

      const schema = Yup.object().shape({
        amount: Yup.number().required(),
        month: Yup.number().required(),
        year: Yup.number().required(),
      })

      await schema.validate(data, {
        abortEarly: false,
      });

      props.onSubmit(data, helpers);
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        const validationErrors: any = {};
        errors.inner.forEach(error => {
          validationErrors[error.path] =  error.message;
        });

        (ref as any).current.setErrors(validationErrors);
      }
    }
  }

  const options = [
    { value: 1, label: "Janeiro"},
    { value: 2, label: "Fevereiro"},
    { value: 3, label: "Março"},
    { value: 4, label: "Abril"},
    { value: 5, label: "Maio"},
    { value: 6, label: "Junho"},
    { value: 7, label: "Julho"},
    { value: 8, label: "Agosto"},
    { value: 9, label: "Setembro"},
    { value: 10, label: "Outubro"},
    { value: 11, label: "Novembro"},
    { value: 12, label: "Dezembro"},
  ];

  return (
    <Form ref={ref} className="form-content" onSubmit={handleSubmit} initialData={props.data}>
      <div className="form-row">
        <div className="form-field form-field--1">
          <MoneyInput name="amount" label="Valor"/>
        </div>
      </div>

      <div className="form-row">
        <div className="form-field form-field--1">
          <SelectInput
            label="Mês de referencia"
            name="month"
            options={options}
          />
        </div>

        <div className="form-field form-field--1">
          <Input type="number" name="year" label="Ano"/>
        </div>
      </div>

      <div className="form-row--last form-field--1">
        <div className="form-field">
          <CheckboxInput name="recurrent" label="Recorrente"/>
        </div>
      </div>

    </Form>
  )
});

export default PaymentEditForm;