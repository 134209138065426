import React from 'react';
// Components
import Header from '../Header';
import LoadingContainer from '../LoadingContainer';
// Styles
import './styles.css';

interface Props  {
  dataLoaded?: boolean;
  activeTab?: 'templates' | 'clients' | 'dashboard'
}

const PageContainer: React.FC<Props> = ({dataLoaded=true, activeTab='clients', children}) => {

  return (
    <>
      <Header active={activeTab}/>
      <main>
        <LoadingContainer dataLoaded={dataLoaded}>
          {children}
        </LoadingContainer>
      </main>
    </>
  )
}

export default PageContainer;