import React, { useState, useEffect, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
// Components
import Pagination from '../../components/Pagination';
import { Button } from '../../components/Buttons';
// Services
import api from '../../services/api';
// Utils
// Hooks
import useDebounce from '../../hooks/useDebounce';
// Styles
import './styles.css';
import PageContainer from '../../components/PageContainer';

const TemplateList = () => {
  const [templates, setTemplates] = useState([{ id: 0, name: '' }]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    async function loadTemplates() {
      const response = await api.get(`/templates/?page=${page}`)
      setCount(response.data.count);
      setTemplates(response.data.results);
      setDataLoaded(true);
    }

    async function searchTemplates(searchTerm: string) {
      const response = await api.get(`/templates/?search=${searchTerm}`);
      setTemplates(response.data);
    }

    if(debouncedSearchText) {
      searchTemplates(debouncedSearchText);
    } else {
      loadTemplates();
    }
  }, [debouncedSearchText, page]);

  return (
    <PageContainer dataLoaded={dataLoaded} activeTab='templates'>
        <div className="main-bar">
          <h1>Modelos</h1>
          <Link to="/template/new/">
            <Button>Adicionar</Button>
          </Link>
        </div>

        <div className="client-content">
          <input 
            type="text"
            placeholder="Busca"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
            value={searchText}
          />

          <div className="list">
          {
            templates.map((template, index) => {
              return (
                <Link to={`/template/${template.id}/`} key={index} className="list--item">
                  <span>{template.name}</span>
                </Link>
              )
            })
          }
          </div>
        </div>

      {!debouncedSearchText &&
        <Pagination activePage={page} pages={count/20} changePage={setPage} />
      }
    </PageContainer>
  );
}

export default TemplateList;
