import React from 'react';
// @types
import Workout from '../../@types/Workout';
import { formatDate } from '../../utils/format';
// Styles
import './styles.css'

interface Props {
  start: string;
  end: string;
  workouts: Workout[];
  label: string;
}

const PrintWorkout: React.FC<Props> = ({ start, end, workouts, label }) => {

  return (
    <div className="print-container">
      <div className="print-title">
        <div>
          { label }
        </div>
        <div>
          { formatDate(start) }-{ formatDate(end) } 
        </div>
      </div>
      <div className="print-content">
        {
          workouts.map((workout) => (
            <div className="print-workout">
              <span>
                Treino { workout.label } - { workout.description }
              </span>
              <table>
                <thead>
                  <th className="name">Exercicio</th>
                  <th className="series">Series</th>
                  <th className="reps">Repetições</th>
                  <th className="weight">Carga</th>
                  <th className="notes">Observações</th>
                </thead>
                <tbody>
                {
                  workout.exercises.map((exercise) => (
                    <tr>
                      <td>{ exercise.name }</td>
                      <td>{ exercise.series }</td>
                      <td>{ exercise.reps }</td>
                      <td>{ exercise.weight }</td>
                      <td>{ exercise.notes }</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default PrintWorkout;