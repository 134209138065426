import React from 'react';
import { Route, Switch } from 'react-router-dom';
// Pages
import ClientList from '../pages/ClientList';
import TemplateList from '../pages/TemplateList';
import TemplateForm from '../pages/TemplateForm';
import WorkoutForm from '../pages/WorkoutForm';
import ClientCreate from '../pages/ClientCreate';
import ClientDetail from '../pages/ClientDetail';
import ReceiptCreate from '../pages/ReceiptCreate';
import ReceiptDetail from '../pages/ReceiptDetail';
import PaymentDetail from '../pages/PaymentDetail';
import PaymentForm from '../pages/PaymentForm';
import ErrorPage from '../pages/ErrorPage';
import Dashboard from '../pages/Dashboard';

const AppRoutes = () => {
  return (
    <Switch>
      <Route component={ClientList} path="/" exact />
      <Route component={Dashboard} path="/dashboard/" exact />
      <Route component={TemplateList} path="/templates/" exact />
      <Route component={TemplateForm} path="/template/:templateId/" exact />
      <Route component={ErrorPage} path="/404/" exact />
      <Route component={ClientCreate} path="/clients/new/" exact />
      <Route component={WorkoutForm} path="/clients/:clientId/workout/:workoutId/" exact />
      <Route component={ClientDetail} path="/clients/:id/" exact/>
      <Route component={ReceiptCreate} path="/clients/:clientId/receipt/new/" exact />
      <Route component={ReceiptDetail} path="/clients/:clientId/receipt/:receiptId/" exact />
      <Route component={PaymentDetail} path="/clients/:clientId/payment/:paymentId/" exact />
      <Route component={PaymentForm} path="/clients/:clientId/payment/:paymentId/form/" exact />
    </Switch>
  )
}

export default AppRoutes;
