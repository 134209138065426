import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler, FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
// Components
import { Button } from '../../components/Buttons';
import PageContainer from '../../components/PageContainer';
import ReceiptForm from '../../components/ReceiptForm';
// Types
import { ReceiptData } from '../../@types/Payment';
// Styles
import './styles.css';
// Services
import api from '../../services/api';

interface Params {
  clientId: string;
}

const ReceiptCreate = () => {
  const history = useHistory();
  const {clientId} = useParams<Params>();

  const [dataLoaded, setDataLoaded] = useState(false)
  const [clientName, setClientName] = useState("");

  const formRef = React.createRef<FormHandles>();
  const [receiptData] = useState<ReceiptData>({
    client: parseInt(clientId),
    date: new Date().toISOString().split("T")[0],
    description: "",
    amount: 0
  });

  useEffect(() => {
    async function getClientData() {
      try {
        const response = await api.get(`/clients/${clientId}/`);
        setClientName(response.data.name);
        setDataLoaded(true);
      } catch (error) {
        history.push('/404/');
      }
    }
    getClientData();
  }, [clientId, history]);
  
  const handleFormSubmit: SubmitHandler<ReceiptData> = async (formData) => {
    const data = { ...formData, client: parseInt(clientId)}
    const response = await api.post('/receipts/', data);
    toast.info('Recibo emitido!');
    history.push(`/clients/${clientId}/receipt/${response.data.id}/`);
  }

  function submitForm() {
    formRef.current?.submitForm();
  }


  return (
    <PageContainer dataLoaded={dataLoaded}>

        <div className="main-bar">
          <h1>Novo Recibo - {clientName}</h1>
          <div className="main-bar--buttons">
            <Button onClick={submitForm}>Emitir</Button>
          </div>
        </div>

        <ReceiptForm ref={formRef} data={receiptData} onSubmit={handleFormSubmit}/>
    </PageContainer>
  );
}

export default ReceiptCreate;
