import React, { useEffect, useRef} from 'react';
import { useField } from '@unform/core';

interface Props  {
  name: string;
  type: string;
  label: string;
  required?: boolean;
}

const Input: React.FC<Props> = ({ name, type, label, required=false, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  return (
    <>
      <label htmlFor={name}>{label}</label>
      <input 
        ref={inputRef}
        defaultValue={defaultValue}
        type={type}
        required={required}
        {...rest}
      />
      <span className="error">{ error && "*"+error}</span>
    </>
  )

}

export default Input;