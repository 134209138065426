import React from 'react';
// Styles
import './styles.css'

const Spinner = () => {

  return (
    <div className="loading-container">
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Spinner;