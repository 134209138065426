import React from 'react';
// Components
import Spinner from '../Spinner';
// Installed
import { CSSTransition } from 'react-transition-group';
// Styles

interface Props  {
  dataLoaded: boolean;
}

const LoadingContainer: React.FC<Props> = ({dataLoaded=true, children}) => {

  return (
    <>
      <CSSTransition in={!dataLoaded} timeout={350} exit unmountOnExit >
        <div className={!dataLoaded?'fadeIn':'fadeOut'}>
          <div className="spinner-container">
            <Spinner />
          </div>
        </div>
      </CSSTransition>
      <CSSTransition in={dataLoaded} timeout={350} apear >
      <div className={dataLoaded?'fadeIn':'fadeOut'}>
        {children}
      </div>
      </CSSTransition>
    </>
  )
}

export default LoadingContainer;