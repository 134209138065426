import React from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
// Types
import { ReceiptData } from '../../@types/Payment';
// Components
import Input from '../Input';
import MoneyInput from '../MoneyInput';
// Configs
import { YupLocaleConfig } from '../../utils/configs';

type Props = {
  data: ReceiptData;
  onSubmit: SubmitHandler<ReceiptData>;
}

const ReceiptForm = React.forwardRef<FormHandles, Props>((props, ref) => {

  const handleSubmit: SubmitHandler<ReceiptData> = async (data, helpers) => {
    try {
      Yup.setLocale(YupLocaleConfig)

      const schema = Yup.object().shape({
        date: Yup.date().required(),
        amount: Yup.number().required(),
        description: Yup.string().required().max(250),
      })

      await schema.validate(data, {
        abortEarly: false,
      });

      props.onSubmit(data, helpers);
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        const validationErrors: any = {};
        errors.inner.forEach(error => {
          validationErrors[error.path] =  error.message;
        });

        (ref as any).current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Form ref={ref} className="form-content" onSubmit={handleSubmit} initialData={props.data}>
      <div className="form-row">
        <div className="form-field form-field--1">
          <Input type="date" name="date" label="Data"/>
        </div>

        <div className="form-field form-field--1">
          <MoneyInput name="amount" label="Valor"/>
        </div>
      </div>

      <div className="form-row form-row--last">
        <div className="form-field field--fill">
          <Input type="text" name="description" label="Descrição"/>
        </div>
      </div>

    </Form>
  )
});

export default ReceiptForm;