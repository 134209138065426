import React from 'react';
// Styles
import './styles.css';

interface Props  {
  value: boolean;
  onToggle?: () => void;
}

const ToggleSwitch: React.FC<Props> = (props) => {
  return (
    <label className="toggle">
      <input 
        className="toggle-checkbox"
        type="checkbox" 
        checked={props.value}
        onChange={props.onToggle}
      />
      <div className="toggle-switch"></div>
      <span className="toggle-label"></span>
    </label>
  )
}

export default ToggleSwitch;