import React from 'react';
// Components
import Routes from './routes';
import { ToastContainer } from 'react-toastify';
// Styles
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
// Context
import { AuthProvider } from './contexts/auth'

function App() {
  return (
    <>
      <AuthProvider>
        <Routes />
      </AuthProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
