import React from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
// Types
import Client from '../../@types/Client';
// Components
import Input from '../Input';
import InputMask from '../InputMask';
import TextArea from '../TextArea';
// Configs
import { YupLocaleConfig } from '../../utils/configs';
import SelectInput from '../SelectInput';
// Utils
import { states } from '../../utils/format';

type Props = {
  data: Client;
  onSubmit: SubmitHandler<Client>;
}

const ClientForm = React.forwardRef<FormHandles, Props>((props, ref) => {

  const handleSubmit: SubmitHandler<Client> = async (data, helpers) => {
    try {
      Yup.setLocale(YupLocaleConfig)

      const schema = Yup.object().shape({
        name: Yup.string().required(),
        date: Yup.date().required(),
        email: Yup.string().email(),
        landline: Yup.string().max(15),
        phone: Yup.string().max(20),
        number: Yup.string().max(10),
        complement: Yup.string().max(50),
        neighborhood: Yup.string().max(30),
        state: Yup.string().max(2),
        city: Yup.string().max(254),
        cep: Yup.string().max(9),
      })

      await schema.validate(data, {
        abortEarly: false,
      });

      props.onSubmit(data, helpers);
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        const validationErrors: any = {};
        errors.inner.forEach(error => {
          validationErrors[error.path] =  error.message;
        });

        (ref as any).current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Form ref={ref} className="form-content" onSubmit={handleSubmit} initialData={props.data}>      
      <div className="form-row">
        <div className="form-field field--fill">
          <Input name="name" type="text" label="Nome*"/>
        </div>

        <div className="form-field">
          <InputMask
            mask="999.999.999-99"
            type="text"
            name="cpf"
            label="CPF"
          />
        </div>
        <div className="form-field">
          <Input type="text" name="rg" label="RG"/>
        </div>

        <div className="form-field">
          <Input type="date" name="date" label="Data de Nascimento*"/>
        </div>
      </div>

      <div className="form-row">
        <div className="form-field field--fill">
          <Input type="text" name="address"  label="Endereço"/>
        </div>
        <div className="form-field form-field--5">
          <Input type="text" name="number" label="Número"/>
        </div>
        <div className="form-field">
          <Input type="text" name="neighborhood" label="Bairro"/>
        </div>
        <div className="form-field form-field--20">
          <Input type="text" name="complement" label="Complemento"/>
        </div>
      </div>

      <div className="form-row">
        <div className="form-field field--fill">
          <Input type="text" name="city" label="Cidade"/>
        </div>
        <div className="form-field form-field--3">
          <SelectInput 
            label="Estado"
            name="state"
            options={states}
          />
        </div>
        <div className="form-field form-field">
          <InputMask
            mask="99999-999"
            type="text"
            name="cep"
            label="CEP"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-field field--fill">
          <Input type="text" name="email"  label="Email"/>
        </div>
        <div className="form-field">
          <InputMask
            mask="(99) 9999-9999"
            type="text"
            name="landline"
            label="Telefone Fixo"
          />
        </div>
        <div className="form-field">
          <InputMask
            mask="(99) 99999-9999"
            type="text"
            name="phone"
            label="Celular"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-field field--fill">
          <TextArea 
            name="notes" 
            rows={5}
            label="Observações"
          />
        </div>
      </div>

    </Form>
  )
});

export default ClientForm;