import React, { createContext, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

// @types
import User from '../@types/User';
// Services
import api from "../services/api";

interface AuthContextData {
  signed: boolean;
  user: User | null;
  loading: boolean;
  isLoadingData: boolean;
  signIn(token: string, user: User): void;
  signOut(): void;
}

const USER_KEY = '@TrainerBoxAuth:user';
const TOKEN_KEY = '@TrainerBoxAuth:token'

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({children}) => {
  const history = useHistory();

  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    async function loadStorageData() {
      const storedUser = localStorage.getItem(USER_KEY);
      const storedToken = localStorage.getItem(TOKEN_KEY);

      if(storedUser && storedToken) {
        prepareApi(JSON.parse(storedToken));
        try {
          const userInfo = await api.get('/user_info/');
          updateUser(userInfo.data);
        } catch (error) {
          handleApiError(error, signOut);
        }
      }
      setLoading(false);
    }
    loadStorageData();
  }, [])

  function prepareApi(token: string) {
    api.defaults.headers.Authorization = `Bearer ${token}`;   
    api.interceptors.request.use(
      (request) => { 
        setIsLoadingData(true);
        return request;
      }
    )   
    api.interceptors.response.use(
      (response) => { 
        setIsLoadingData(false);
        return response
      }, 
      (error) => {
        console.log(error);
        setIsLoadingData(false);
        if(error.response.status === 401) {
          signOut();
          toast.error('Ocorreu um erro. Faça o Login Novamente');
          return error;
        } else {
          history.push('/404/');
          return error;
        }
      }
    )   
  }

  function updateUser(user: User) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    setUser(user);
    return;
  }

  function signIn(token: string, user: User) {
    setUser(user);
    prepareApi(token);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  }

  function signOut() {
    localStorage.setItem(USER_KEY, '');
    localStorage.setItem(TOKEN_KEY, '');
    setUser(null);
  }

  return (
    <AuthContext.Provider value={{
      signed: !!user, 
      user: user, 
      loading: loading,
      signIn,
      signOut,
      isLoadingData
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(){
  const context = useContext(AuthContext);
  return context;
}

export function handleApiError(error: any, signOutFunction: () => void) {
  console.log(error);
  if (error.response.status === 401) {
    signOutFunction();
    toast.error('Ocorreu um erro. Faça o Login Novamente');
  }
}