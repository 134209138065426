import React from 'react';

const ErrorPage = () => {
  return (
    <>
      <main className="login-card">

        <div className="login-card--logo">
          <span>Ocorreu um erro inesperado!</span>
        </div>

      </main>

    </>
  );
}

export default ErrorPage;
