import React from 'react';
// @Types
import Workout from '../../@types/Workout';
import Exercise from '../../@types/Exercise';
// Components
import { FiPlus } from 'react-icons/fi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ExerciseCard from '../ExerciseCard';
// Styles
import './styles.css';
import { DeleteButton } from '../Buttons';

interface Props {
  workout: Workout;
  onChange: (workout: Workout) => void
  onRemove: () => void
}

const WorkoutCard: React.FC<Props> = ({ workout, onRemove: handleRemove, onChange: handleChange }) => {

  function setDescription(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const newWorkout = {...workout, description: value}
    handleChange(newWorkout);
  }

  function addExercise() {
    const newExercise = {} as Exercise;
    newExercise.id = workout.exercises.length.toString();
    newExercise.name = "";
    newExercise.series = "";
    newExercise.reps = "";
    newExercise.weight = "";
    newExercise.notes = "";
    const newExercises = [...workout.exercises, newExercise]
    const newWorkout = {...workout, exercises: newExercises}
    handleChange(newWorkout);
  }

  function handleExerciseCardChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { name, value } = event.target;
    const changedExercise = {...workout.exercises[index], [name]: value} as Exercise;
    const newExercises = workout.exercises.slice();
    newExercises[index] = changedExercise;
    const newWorkout = {...workout, exercises: newExercises}
    handleChange(newWorkout);
  }

  function rmExercise(index: number) {
    const newExercises = workout.exercises.slice();
    newExercises.splice(index, 1);
    const newWorkout = {...workout, exercises: newExercises}
    handleChange(newWorkout);
  }

  function handleDragEnd(result: any) {
    const reorder = (list: any, startIndex: any, endIndex: any) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    if (!result.destination) {
      return;
    }

    const newExercises = reorder(
      workout.exercises,
      result.source.index,
      result.destination.index
    );

    const newWorkout = {...workout, exercises: newExercises as Exercise[]}
    handleChange(newWorkout);
  }

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "var(--text-color-blue)" : "white",
  });

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging ? "lightblue" : "white",
    ...draggableStyle
  });

  return (
    <div className="exercise-container">
      <div className="exercise-detail">
        <div className="exercise-field exercise-field--nocard field--fill">
          <label htmlFor="description">Descrição:</label>
          <input 
            type="text" 
            name="description" 
            value={workout.description}
            onChange={setDescription}
          />
        </div>
        <DeleteButton onClick={handleRemove}/>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {workout.exercises.map((exercise, index) => (
                <Draggable key={exercise.id} index={index} draggableId={exercise.id}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {
                        <ExerciseCard 
                          exercise={exercise}
                          index={index}
                          onChange={handleExerciseCardChange}
                          onRemove={rmExercise}
                        />
                      }
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
        
      <button 
        className="exercise-button-add"
        onClick={addExercise}
      >
        <FiPlus />
      </button>
    </div>
  )
}

export default WorkoutCard;