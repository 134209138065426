import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// Components
import Header from '../../components/Header';
import LoadingContainer from '../../components/LoadingContainer';
// Services
import api from '../../services/api';
// Utils
import { formatDate } from '../../utils/format';
// Styles
import './styles.css';

interface ClientList {
  id: number;
  name: string;
  date: string;
}

interface PaymentList {
  amount: number;
  due_date: string;
  client: ClientList;
}

interface WorkoutList {
  end: string;
  client: ClientList;
}

const Dashboard = () => {
  const history = useHistory();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [birthdays, setBirthdays] = useState<ClientList[]>([]);
  const [workoutsTodo, setWorkoutsTodo] = useState<WorkoutList[]>([]);

  useEffect(() => {
    async function getDashboardData() {
      const response = await api.get(`/dashboard/`);
      setBirthdays(response.data.birthdays)
      setWorkoutsTodo(response.data.workouts_todo)
      setDataLoaded(true);
    }
    getDashboardData();
  }, [history]);

  return (
    <>
      <Header active="dashboard"/>

      <div className='dashboard'>
        <div className="dashboard-card dashboard-card--2">
          <LoadingContainer dataLoaded={dataLoaded}>
            <>
              <div className="dashboard-card--header">
                <h2>Aniversariantes</h2>
              </div>
              { birthdays.map((client, index) => (
                  <Link 
                    to={`/clients/${client.id}/`} 
                    key={index} 
                    className="list--item"
                  >
                    <span>{client.name}</span>
                    <span className="flex-column-small align-right">{formatDate(client.date)}</span>
                  </Link>
                ))
              }
            </>
          </LoadingContainer>
        </div>
        <div className="dashboard-card dashboard-card--2">
          <LoadingContainer dataLoaded={dataLoaded}>
            <>
              <div className="dashboard-card--header">
                <h2>Treinos à vencer</h2>
              </div>
              { workoutsTodo.map((workout, index) => { 
                  let isBehind = false;
                  const date = new Date(workout.end+" 00:01");
                  const today = new Date();
                  if(today > date) {
                    isBehind = true;
                  }
                  return (
                    <Link 
                      to={`/clients/${workout.client.id}/`} 
                      key={index} 
                      className={"list--item "+(isBehind ? "payment-CANCELED" : "" )}
                    >
                      <span>{workout.client.name}</span>
                      <span>{ formatDate(workout.end) }</span>
                    </Link>
                  )
                })
              }
            </>
          </LoadingContainer>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
