import React from 'react';
// Components
import { FiPlus } from 'react-icons/fi';
// @Types
import Workout from '../../@types/Workout';

import './styles.css'

interface Props {
  active: number;
  workouts: Workout[];
  onChange: (index: number) => void;
  onAdd: () => void;
}

const WorkoutTabs: React.FC<Props> = ({ active, workouts, onChange, onAdd }) => {
  return (
    <div className="tabs">
      <ul>
        { 
          workouts.map((workout, index) => {
            return (
              <li 
                key={ index } 
                className={index === active ? "active" : ""}
                onClick={ () => onChange(index) }
              >
                { workout.label }
              </li>
            );
          })
        }
      </ul>
    <button onClick={onAdd}><FiPlus /></button>
  </div>
  )
}

export default WorkoutTabs;