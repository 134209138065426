import React from 'react';
// Styles
import './styles.css';

interface Props {
  pages: number;
  activePage: number;
  changePage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination: React.FC<Props> = (props) => {

  function renderItems() {
    const items = []
    for (let pageIndex = 1; pageIndex <= props.pages; pageIndex++) {
      items.push(
        <li 
          key={pageIndex}
          onClick={(() => props.changePage(pageIndex))}
          className={props.activePage === pageIndex ? "active" : ""}
        >
          {pageIndex}
        </li>
      )
    }
    return items;
  }

  return (
    <div className="pagination-container">
      <ul className="pagination">
        { renderItems() }
      </ul>
    </div>
  );

}

export default Pagination;
