import React from 'react';
import { BrowserRouter} from 'react-router-dom';
// 
import { useAuth } from '../contexts/auth';
// Pages
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';

const Routes = () => {
  const { signed } = useAuth()

  return (
    <BrowserRouter>
      {
        signed ? <AppRoutes /> : <AuthRoutes />
      }
    </BrowserRouter>
  )
}

export default Routes;
