import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { SubmitHandler, FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

// Components
import ClientForm from '../../components/ClientForm';
import { Button, DeleteButton, AddLinkButton } from '../../components/Buttons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// Services
import api from '../../services/api';
// Types
import Client from '../../@types/Client';
// Utils
import { formatAmount, formatDate } from '../../utils/format';
// Styles
import 'react-tabs/style/react-tabs.css';
import './styles.css';
import ToggleSwitch from '../../components/ToggleSwitch';
import PageContainer from '../../components/PageContainer';

interface Params {
  id: string;
}

const ClientDetail = () => {
  const formRef = React.createRef<FormHandles>();

  const { id } = useParams<Params>();
  const history = useHistory();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [clientData, setClientData] = useState({
      id: 0,
      name: "",
      date: "",
      address: "",
      number: "",
      complement: "",
      neighborhood: "",
      state: "",
      city: "",
      cep: "",
      email: "",
      cpf: "",
      rg: "",
      landline: "",
      phone: "",
      notes: ``,
      is_active: true,
      workouts: [
        {id: 0, label: ""},
      ],
      payments: [
        {id: 0, label: "", status: "", amount: 0.0 },
      ],
      receipts: [
        {id: 0, date: "", description: "", amount: 0.0 },
      ],
    }
  );

  useEffect(() => {
    async function getClientData() {
      const response = await api.get(`/clients/${id}/`);
      setClientData(response.data);
      setDataLoaded(true);
    }
    getClientData();
  }, [history, id]);

  useEffect(() => {
    formRef.current?.setData(clientData);
  }, [clientData, formRef])

  const handleFormSubmit: SubmitHandler<Client> = async (data) => {
    const response = await api.put(`/clients/${clientData.id}/`, data);
    setClientData({...clientData, ...response.data})
    toast.info('Cliente atualizado com sucesso!');
  }

  const handleClientActiveToggle = async () => {
    const newClientData = {...clientData, is_active: !clientData.is_active}
    await api.put(`/clients/${clientData.id}/`, newClientData);
    setClientData(newClientData)
    if(newClientData.is_active) {
      toast.success('Cliente ativado com sucesso!');
    } else {
      toast.info('Cliente desativado com sucesso!');
    }
  }

  function submitForm() {
    formRef.current?.submitForm();
  }

  async function handleDelete() {
    const deleteClient = window.confirm("Tem certeza que deseja deletar esse cliente?");
    if(deleteClient) {
      await api.delete(`/clients/${clientData.id}/`);
      toast.info('Cliente deletado com sucesso!');
      history.push('/');
    }
  }

  return (
    <PageContainer dataLoaded={dataLoaded}>
        <div className="main-bar">
          <h1>{clientData.name}</h1>
          <div className="main-bar--buttons">
            <ToggleSwitch 
              value={clientData.is_active}
              onToggle={handleClientActiveToggle}
            />
            <DeleteButton onClick={handleDelete}/>
          </div>
        </div>

        <Tabs>
          <TabList>
            <Tab>Financeiro</Tab>
            <Tab>Treinos</Tab>
            <Tab>Dados</Tab>
          </TabList>

          <TabPanel>
            <div className="main-bar">
              <div className="main-bar--h2">
                <h2>Mensalidades</h2>
              </div>
              <AddLinkButton to={`/clients/${clientData.id}/payment/new/form/`} />
            </div>

            <div className="list list-3-colummns">
              {
                clientData.payments.map((payment, index) => {
                  let statusStyle = "payment-"+payment.status;
                  const today = new Date();
                  return (
                    <Link 
                      to={`/clients/${clientData.id}/payment/${payment.id}/`} 
                      key={index} 
                      className={"list--item "+statusStyle}
                    >
                      <span>{payment.label}</span>
                      <span className="flex-column-small align-right">{ formatAmount(payment.amount) }</span>
                    </Link>
                  )
                })
              }
            </div>

            <div className="main-bar">
              <div className="main-bar--h2">
                <h2>Recibos</h2>
              </div>
              <AddLinkButton to={`/clients/${clientData.id}/receipt/new/`} />
            </div>

            <div className="list list-3-colummns">
              {
                clientData.receipts.map((receipt, index) => {
                  return (
                    <Link 
                      to={`/clients/${clientData.id}/receipt/${receipt.id}/`} 
                      key={index} 
                      className="list--item"
                    >
                      <span className="flex-column-small">{ formatDate(receipt.date) }</span>
                      <span>{receipt.description}</span>
                      <span className="flex-column-small align-right">{ formatAmount(receipt.amount) }</span>
                    </Link>
                  )
                })
              }
            </div>
          </TabPanel>

          <TabPanel>
              <div className="main-bar">
                <div className="main-bar--h2">
                  <h2>Treinos</h2>
                </div>
                <AddLinkButton to={`/clients/${clientData.id}/workout/new/`} />
              </div>

              <div className="list">
                {
                  clientData.workouts.map((workout, index) => {
                    return (
                      <Link 
                        to={`/clients/${clientData.id}/workout/${workout.id}/`} 
                        key={index} className="list--item"
                      >
                        <span>#{clientData.workouts.length - index}</span>
                        <span>{workout.label}</span>
                      </Link>
                    )
                  })
                }
              </div>
          </TabPanel>

          <TabPanel>
            <div className="main-bar">
              <div className="main-bar--h2">
                <h2>Dados</h2>
              </div>
              <Button onClick={submitForm}>Atualizar</Button>
            </div>
            <ClientForm ref={formRef} data={clientData} onSubmit={handleFormSubmit}/>
          </TabPanel>
        </Tabs>

    </PageContainer>
  );
}

export default ClientDetail;
