import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// Components
import PageContainer from '../../components/PageContainer';
import { Button, CancelButton, EditButton, PayButton, DownloadButton } from '../../components/Buttons';
import { ReceiptCard, ReceiptRef} from '../../components/ReceiptCard';
import { AppModal } from '../../components/AppModal';
import { FiRefreshCcw, FiX} from 'react-icons/fi';
import { CSSTransition } from 'react-transition-group';
// Types
import { Payment } from '../../@types/Payment';
// Styles
import './styles.css';
// Services
import api from '../../services/api';
import { formatAmount, formatDate, months, statuses } from '../../utils/format';

interface Params {
  clientId: string;
  paymentId: string;
}

const PaymentDetail = () => {
  const history = useHistory();
  const {clientId, paymentId} = useParams<Params>();

  const receiptRef = useRef<ReceiptRef>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [paymentData, setPaymentData] = useState<Payment>();
  const [paymentDate, setPaymentDate] = useState(new Date().toISOString().split("T")[0]);
  const [paymentStatus, setPaymentStatus] = useState("OPEN");

  useEffect(() => {
    async function getPaymentData() {
      try {
        const response = await api.get(`/payments/${paymentId}/`);
        const payment = response.data;
        setPaymentData(response.data);
        setPaymentStatus(payment.status);
        setDataLoaded(true);
      } catch (error) {
        history.push('/404/');
      }
    }
    getPaymentData();
  }, [clientId, paymentId, history]);

  async function handleCancel() {
    const cancelPayment = window.confirm("Tem certeza que cancelar essa mensalidade?");
    if(cancelPayment) {
      const response = await api.patch(`/payments/${paymentId}/cancel/`);
      setPaymentData(response.data);
      setPaymentStatus(response.data.status);
      toast.info('Pagamento cancelado com sucesso!');
      history.push(`/clients/${clientId}/`);
    }
  }

  async function handlePayment() {
    const data = { date: paymentDate }
    const response = await api.patch(`/payments/${paymentId}/pay/`, data);
    toast.success('Mensalidade paga com sucesso!');
    
    setPaymentData(response.data.payment);
    setPaymentStatus(response.data.payment.status);

    closePayPayment();
    if(response.data.next_payment) {
      toast.info('Próxima mensalidade lançada!');
    }
  }

  function closePayPayment() { setModalIsOpen(false); }
  function openPayPayment() { setModalIsOpen(true); }

  function exportReceipt() {
    receiptRef.current?.exportReceipt();
  }

  return (
    <>
      <PageContainer dataLoaded={dataLoaded}>

        <div className="main-bar">
          <h1>Mensalidade - {paymentData?.client.name}</h1>
          <div className="main-bar--buttons">
            {paymentData?.receipt && 
              <DownloadButton onClick={exportReceipt} />
            }
            {paymentData?.status === "OPEN" &&
              <>
                <PayButton onClick={openPayPayment} />
                <EditButton to={`/clients/${clientId}/payment/${paymentId}/form/`}/>
                <CancelButton onClick={handleCancel}/>
              </>
            }
          </div>
        </div>

        <div className="info-row">
          <div className="info-field">
          </div>
          <div className="info-status">
            {paymentData?.recurrent && <div className="status"> <FiRefreshCcw /> </div>}
            <div className={"status status-"+paymentStatus}>
              { statuses[paymentStatus] }
            </div>
          </div>
        </div>

        <div className="info-row">
          <div className="info-field">
            <label>Referente à:</label>
            <span>{months[paymentData?.month!]}/{paymentData?.year}</span>
          </div>
          <div className="info-field payment-PAYED">
            <label>Valor:</label>
            <span>{ formatAmount(paymentData?.amount!) }</span>
          </div>
        </div>


      <AppModal 
        isOpen={modalIsOpen}
        onClose={closePayPayment}
      >
        <div className="main-bar">
          <h1 className="nav-title">{months[paymentData?.month!]}/{paymentData?.year}</h1>
          <Button onClick={closePayPayment}><FiX/></Button>
        </div>

        <div className="form-row--detail">
          <div className="form-field--1">
            <label htmlFor="description">Valor</label>
            <input 
              type="text" 
              value={formatAmount(paymentData?.amount!)}
              disabled
            />
          </div>
        </div>

        <div className="form-row--detail">
          <div className="form-field--1">
            <label htmlFor="description">Data do pagamento</label>
            <input 
              type="date" 
              value={paymentDate}
              onChange={(e) => setPaymentDate(e.target.value)}
            />
          </div>
        </div>

        <button
          className="button--pay button--fill"
          onClick={handlePayment}>
        Pagar
        </button>
      </AppModal>

      </PageContainer>
      <CSSTransition
          in={(dataLoaded && paymentData?.receipt) ? true : false}
          timeout={350}
          apear
        >
        <div className={((dataLoaded && paymentData?.receipt) ? true : false)?'fadeIn':'fadeOut'}>
          {paymentData?.receipt && 
            <ReceiptCard ref={receiptRef} data={paymentData.receipt}/>
          }
        </div>
      </CSSTransition>
    </>
  );
}

export default PaymentDetail;
