import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// Components
import PageContainer from '../../components/PageContainer';
import WorkoutEdit from '../../components/WorkoutEdit';
import { Button, DeleteButton } from '../../components/Buttons';
// Types
import Workout from '../../@types/Workout';
// Styles
import './styles.css';
// Services
import api from '../../services/api';

interface Params {
  templateId: string;
}

const TemplateForm = () => {
  const history = useHistory();
  const {templateId} = useParams<Params>();

  const [templateLabel, setTemplateLabel] = useState('Novo Modelo');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [workouts, setWorkouts] = useState<Workout[]>([]);

  const [templateName, setTemplateName] = useState('');

  useEffect(() => {
    // Fetch workout data
    async function getTemplateData() {
      const response = await api.get(`/templates/${templateId}/`);
      setWorkouts(response.data.data);
      setTemplateName(response.data.name);
      setTemplateLabel(`#${response.data.id}  - ${response.data.name}`);
      setDataLoaded(true);
    }

    if(templateId !== "new") {
      getTemplateData();
    } else {
      setDataLoaded(true);
    }
  }, [templateId, history]);
  
  async function handleFormSubmit() {
    if(templateName) {
      const templateObject = {
        name: templateName,
        data: workouts,
      }
  
      let message = ""
      if(templateId === "new") {
        // Create
        await api.post('/templates/', templateObject);
        message = 'Modelo criado com sucesso!';
      } else {
        // Update
        await api.put(`/templates/${templateId}/`, templateObject);
        message = 'Modelo atualizado com sucesso!';
      }
      toast.info(message);
      history.push(`/templates/`);
    } else {
      toast.error('Preencha o nome do modelo!');
    }
  }

  async function handleDelete() {
    const deleteTemplate = window.confirm("Tem certeza que deseja deletar esse modelo?");
    if(deleteTemplate) {
      await api.delete(`/templates/${templateId}/`);
      toast.info('Modelo deletado com sucesso!');
      history.push('/templates/');
    }
  }


  return (
    <PageContainer activeTab='templates' dataLoaded={dataLoaded}>

        <div className="main-bar">
          <h1>{templateLabel}</h1>
          <div className="main-bar--buttons">
            { (templateId !== "new") &&
              <DeleteButton onClick={handleDelete}/>
            }
            <Button onClick={handleFormSubmit}>Finalizar</Button>
          </div>
        </div>

        <div>
          <div className="workout-detail--container">
            <div className="workout-detail--field">
              <label htmlFor="date">Nome:</label>
              <input 
                type="text" 
                name="template_name"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </div>
          </div>

          <WorkoutEdit 
            workouts={workouts}
            onChange={(workouts) => setWorkouts(workouts)}
          />
        </div>

    </PageContainer>
  );
}

export default TemplateForm;
