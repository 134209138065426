import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitHandler, FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
// Components
import PageContainer from '../../components/PageContainer';
import ClientForm from '../../components/ClientForm';
import { Button } from '../../components/Buttons';
// Types
import Client from '../../@types/Client';
// Services
import api from '../../services/api';
// Styles
import './styles.css';

const ClientCreate = () => {
  const formRef = React.createRef<FormHandles>();

  const history = useHistory();
  const [clientData] = useState<Client>({
      name: "",
      date: "",
      address: "",
      number: "",
      complement: "",
      neighborhood: "",
      state: "",
      city: "",
      cep: "",
      email: "",
      cpf: "",
      rg: "",
      landline: "",
      phone: "",
      notes: ``,
      is_active: true,
    }
  );

  const handleFormSubmit: SubmitHandler<Client> = async (data) => {
    const response = await api.post('/clients/', data);
    toast.info('Cliente adicionado com sucesso!');
    history.push(`/clients/${response.data.id}/`);
  }

  function submitForm() {
    formRef.current?.submitForm();
  }

  return (
    <PageContainer>
      <div className="main-bar">
        <h1>Novo Cliente</h1>
        <Button onClick={submitForm}>Adicionar</Button>
      </div>

      <ClientForm ref={formRef} data={clientData} onSubmit={handleFormSubmit}/>
    </PageContainer>
  );
}

export default ClientCreate;
