import React, { useState } from 'react'
// Components
import WorkoutTabs from '../WorkoutTabs';
import WorkoutCard from '../WorkoutCard';
// Styles
import './styles.css';
// @Types
import Workout from '../../@types/Workout';
import Exercise from '../../@types/Exercise';

interface Props {
  workouts: Workout[];
  onChange: (workouts: Workout[]) => void;
}

const WorkoutEdit: React.FC<Props> = ({ workouts, onChange }) => {

  const [activeWorkout, setActiveWorkout] = useState(0);

  function addWorkout() {
    let newLabel = workouts.length === 0 ? "A" : String.fromCharCode(workouts[workouts.length-1].label.charCodeAt(0)+1);
    const newWorkout = {} as Workout;
    newWorkout.label = newLabel;
    newWorkout.description = "";
    newWorkout.exercises = [] as Exercise[];
    onChange([...workouts, newWorkout]);
    setActiveWorkout(workouts.length);
  }

  function handleWorkoutRemove() {
    let newWorkouts = workouts.slice();
    newWorkouts.splice(activeWorkout, 1);
    const newActiveWorkout = activeWorkout-1 < 0 ? 0 : activeWorkout-1;

    if(newWorkouts.length !== 0) {
      let newLabel = "A";
      newWorkouts = newWorkouts.map((workout) => {
        workout.label = newLabel;
        newLabel = String.fromCharCode(newLabel.charCodeAt(0)+1)
        return workout;
      })
    }
    setActiveWorkout(newActiveWorkout);
    onChange(newWorkouts);
  }

  function handleWorkoutChange(workout: Workout) {
    const newWorkouts = workouts.slice();
    newWorkouts[activeWorkout] = workout;
    onChange(newWorkouts);
  }

  return (
    <>
      <WorkoutTabs 
        active={activeWorkout}
        workouts={workouts}
        onChange={(index) => setActiveWorkout(index)}
        onAdd={addWorkout}
      />

      {workouts.length !== 0 &&
        <WorkoutCard 
          workout={workouts[activeWorkout]}
          onChange={handleWorkoutChange}
          onRemove={handleWorkoutRemove}
        />
      }
    </>
  )
}

export default WorkoutEdit;