import React from 'react';
import { Route, Switch } from 'react-router-dom';
// Pages
import Login from '../pages/Login';

const AuthRoutes = () => {
  return (
    <Switch>
      <Route component={Login} path="/" exact />
      <Route component={Login} />
    </Switch>
  )
}

export default AuthRoutes;
