import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler, FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
// Components
import { Button } from '../../components/Buttons';
import PageContainer from '../../components/PageContainer';
import PaymentEditForm from '../../components/PaymentEditForm';
// Types
import Client from '../../@types/Client';
import { PaymentData } from '../../@types/Payment';
// Styles
import './styles.css';
// Services
import api from '../../services/api';

interface Params {
  clientId: string;
  paymentId: string;
}

const PaymentForm = () => {
  const history = useHistory();
  const {clientId, paymentId} = useParams<Params>();
  
  const formRef = React.createRef<FormHandles>();
  
  const today = new Date();
  
  const [dataLoaded, setDataLoaded] = useState(false)
  const [clientData, setClientData] = useState<Client>({} as Client);

  const [paymentData, setPaymentData] = useState<PaymentData>({
    amount: 0,
    month: today.getMonth(),
    year: today.getFullYear(),
    recurrent: true,
  });

  useEffect(() => {
    formRef.current?.setData(paymentData);
  }, [paymentData])

  useEffect(() => {
    async function getPaymentData() {
      try {
        const response = await api.get(`/payments/${paymentId}/`);
        if(response.data.status !== "OPEN") {
          toast.error('Mensalidade já paga/cancelada');
          history.push(`/clients/${clientId}/`);
        }
        setClientData(response.data.client);
        setPaymentData(response.data);
        setDataLoaded(true);
      } catch (error) {
        history.push('/404/');
      }
    }

    async function getClientData() {
      try {
        const response = await api.get(`/clients/${clientId}/`);
        setClientData(response.data);
        setDataLoaded(true);
      } catch (error) {
        history.push('/404/');
      }
    }

    if(paymentId !== "new") {
      getPaymentData();
    } else {
      getClientData();
    }
  }, [clientId, paymentId, history]);
  
  const handleFormSubmit: SubmitHandler<PaymentData> = async (formData) => {
    const data = { ...formData, client: parseInt(clientId)}

    if(paymentId !== "new") {
      await api.put(`/payments/${paymentId}/`, data);
      toast.info('Mensalidade atualizada!');
      history.push(`/clients/${clientId}/payment/${paymentId}/`);
    } else {
      await api.post('/payments/', data);
      toast.info('Mensalidade cadastrada!');
      history.push(`/clients/${clientId}/`);
    }
  }

  function submitForm() {
    formRef.current?.submitForm();
  }


  return (
    <PageContainer dataLoaded={dataLoaded}>
        <div className="main-bar">
          <h1>Nova Mensalidade - {clientData.name}</h1>
          <div className="main-bar--buttons">
            <Button onClick={submitForm}>Finalizar</Button>
          </div>
        </div>

        <PaymentEditForm ref={formRef} data={paymentData} onSubmit={handleFormSubmit}/>
    </PageContainer>
  );
}

export default PaymentForm;
