import React from 'react';
// Components
import { DeleteButton } from '../Buttons';
// @Types
import Exercise from '../../@types/Exercise';

interface Props {
  exercise: Exercise;
  index: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onRemove: (index: number) => void;
}

const ExerciseCard: React.FC<Props> = ({ exercise, index, onChange, onRemove }) => {
  return (
    <div key={index} className="exercise-card">
      <div className="exercise-row">
        <div className="exercise-field field--fill">
          <label htmlFor="name">Exercicio:</label>
          <input 
            type="text" 
            name="name"
            value={exercise.name}
            onChange={ (e) => onChange(e, index)}
          />
        </div>
        <div className="exercise-field">
          <label htmlFor="series">Series:</label>
          <input 
            type="text" 
            name="series"
            value={exercise.series}
            onChange={ (e) => onChange(e, index)}
          />
        </div>
        <div className="exercise-field">
          <label htmlFor="reps">Repetições:</label>
          <input 
            type="text" 
            name="reps"
            value={exercise.reps}
            onChange={ (e) => onChange(e, index)}
          />
        </div>
      </div>
      <div className="exercise-row">
        <div className="exercise-field">
          <label htmlFor="weight">Carga:</label>
          <input 
            type="text" 
            name="weight"
            value={exercise.weight}
            onChange={ (e) => onChange(e, index)}
          />
        </div>
        <div className="exercise-field field--fill">
          <label htmlFor="notes">Observações:</label>
          <input 
            type="text" 
            name="notes"
            value={exercise.notes}
            onChange={ (e) => onChange(e, index)}
          />
        </div>
        <DeleteButton onClick={() => {onRemove(index)}} />
      </div>
    </div>
  )
}

export default ExerciseCard;