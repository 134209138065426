import React, { useState, useEffect, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
// Components
import Pagination from '../../components/Pagination';
import { Button } from '../../components/Buttons';
import ToggleSwitch from '../../components/ToggleSwitch';
// Services
import api from '../../services/api';
// Utils
import { formatDate } from '../../utils/format';
// Hooks
import useDebounce from '../../hooks/useDebounce';
// Styles
import './styles.css';
import PageContainer from '../../components/PageContainer';

const ClientList = () => {
  const [clients, setClients] = useState([{ id:0, name: '', date: '' }]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 500);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(false);

  useEffect(() => {
    async function loadClients() {
      const response = await api.get(`/clients/?page=${page}`)
      setCount(response.data.count);
      setClients(response.data.results);
      setDataLoaded(true);
    }

    async function loadAllActiveClients() {
      const response = await api.get('/clients/?active=true')      
      setClients(response.data);
    }

    async function searchClients(searchTerm: string) {
      let url = `/clients/?search=${searchTerm}`
      if (isActiveFilter){
        url += '&active=true'
      }
      const response = await api.get(url);
      setClients(response.data);
    }

    if(debouncedSearchText) {
      searchClients(debouncedSearchText);
    } else if (isActiveFilter) {
      loadAllActiveClients();
    } else {
      loadClients();
    }
  }, [debouncedSearchText, page, isActiveFilter]);

  return (
    <PageContainer dataLoaded={dataLoaded}>
        <div className="main-bar">
          <h1>Clientes</h1>
          <div className="main-bar--buttons">
            <ToggleSwitch 
              value={isActiveFilter}
              onToggle={() => setIsActiveFilter(!isActiveFilter)}
            />
            <Link to="/clients/new/">
              <Button>Adicionar</Button>
            </Link>
          </div>
        </div>

        <div className="client-content">
          <input 
            type="text"
            placeholder="Busca"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
            value={searchText}
          />

          <div className="list">
          {
            clients.map((client, index) => {
              return (
                <Link to={`/clients/${client.id}/`} key={index} className="list--item">
                  <span>{client.name}</span>
                  <span>{client.date ? formatDate(client.date) : "-"}</span>
                </Link>
              )
            })
          }
          </div>
        </div>

      {!debouncedSearchText &&
        <Pagination activePage={page} pages={count/20} changePage={setPage} />
      }
    </PageContainer>
  );
}

export default ClientList;
