export const YupLocaleConfig = {
  mixed: {
    default: 'inválido',
    required: 'campo requerido',
    notType: 'data inválida',
  },
  string: {
    email: 'email inválida',
    max: 'deve ter no máximo ${max} caracteres'
  }
}